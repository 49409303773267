
import React from "react";
import "./aa.css";
import TreeNode from "./nodeTree";
const DocumentsTree = ({ documents, }) => {
  return (
    <div className="documents-tree-container">
      {Object.keys(documents).map((category) => (
        <TreeNode
          key={category}
          node={{ name: category, ...documents[category] }}
        />
      ))}
    </div>
  );
};

export default DocumentsTree;
