import axios from "axios"

export const loginUser =async ({email, password})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/users/sp-login`, { email, password })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
}

export const addUser = async({email, password, enabled, role, businessUnit, name, department}) =>{
  return axios.post(`${process.env.REACT_APP_API_URL}/service-providers/new`, {email, password, enabled, role, businessUnit, name, department})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
}

export const getSignedAgreementList =async ({page, filters})=>{
    return axios.post(`${process.env.REACT_APP_SIGNED_AGREEMENT_API}/agreements/SignedagreementList`, {page, filters})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
}

export const getAttacchedFiles =async ({id})=>{
  return axios.get(`${process.env.REACT_APP_SIGNED_AGREEMENT_API}/attachedfiles/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const createObligationApi =async ({body})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/createobligation`, {...body })
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const attachFiles =async ({body})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/attachfiles`, {...body })
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const updateObligationApi =async ({body})=>{
  return axios.put(`${process.env.REACT_APP_API_URL}/updateobligation`, {...body })
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}
export const bulkUpdateObligationApi =async ({data})=>{
  return axios.put(`${process.env.REACT_APP_API_URL}/bulkupdateobligation`, {data})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}


export const getObligationsForAgreement = async ({local, agreementId})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getobligations`, {local, agreementId})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const getauditHistory = async ({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/audithistory/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}


export const getCompletedObligation = async ()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/completed`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const getAcceptObligations = async({department, businessUnit,page})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getacceptobligations`, {department, businessUnit,page})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}
export const acceptObligation = async({id, recFrequency})=>{
  return axios.put(`${process.env.REACT_APP_API_URL}/acceptobligation`, {id, recFrequency})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}


export const getAssignedObligations =async ({department,page, businessUnit})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getassignedobligation`, { department, page, businessUnit })
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const getActionedObligations =async ({department,page, businessUnit})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getactionedobligation`, { department, page, businessUnit })
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}


export const getObligationDetails = async ({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getobligationDetails/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const getObligationFiles = async ({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/attachedfilestobligation/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}


export const createLocalAgreement =async ({agreementData})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/createagreement`,{agreementData} )
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}

export const getLocalAgreements = async ({page, filters})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/docslocker`, {page, filters})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}
export const getLocalAgreementsCSV = async ({page, filters})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/docslockercsv`, {page, filters})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}
export const getLocalAgreementDetails = async ({id}) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/localagreement/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error logging in!", error);
    throw error;
  });
}


export const getAllObligations = async({page, filters}) =>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getallobligations`,{page, filters})
  .then(response => response.data)
  .catch(error =>{
    console.error("There was an error getting obligation", error);
    throw error;
  });
}


export const getFile = async({id}) =>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getfile`,{id})
  .then(response => response.data)
  .catch(error =>{
    console.error("There was an error getting file", error);
    throw error;
  });
}

export const addBu = async({name, enabled})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/addbu`, {name, enabled})
  .then(response => response.data)
  .catch(error =>{
    console.error("There was an error adding bu", error);
    throw error;
  });
}

export const updateBuDetails = async({id, name, enabled})=>{
  return axios.put(`${process.env.REACT_APP_API_URL}/updateBu/${id}`, {name, enabled})
  .then(response => response.data)
  .catch(error =>{
    console.error("There was an error updating bu", error);
    throw error;
  });
}

export const getBuData  = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getbudata`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu name", error);
    throw error;
  });
} 

export const getBuList = async ({page}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/getbulist`, {page})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}

export const getBuName = async({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getbuname/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu name", error);
    throw error;
  });
}


export const getBuDetails = async ({page, id}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/getbudetails/${id}`, {page})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}

export const getComplianceStatus = async ({filters, page}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/getcomplaincestatus`,{filters, page} )
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}
export const fetchBuOptions = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getbuoptions`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}

export const getMasterObligation = async({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getMasterObligation/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}

export const deleteObligation = async ({id}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/deleteMasterObligation/${id}` )
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}

export const getTitlesOfAgreement = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/titlesofagreement`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}
export const getNamesofAgreement = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/namesofagreement`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}

export const  getNotifications=async({filter})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getNotifications`, {...filter} )
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting notifications", error);
    throw error;
  });
}



export const getStandardObligations = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getstandardobligations`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}


export const getUserDetails = async({email})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getUserDetails`, {email})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting user data", error);
    throw error;
  });
}

export const getDocuments = async({filter})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getdocuments`, {filter})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting user data", error);
    throw error;
  });
}
