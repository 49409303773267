import React, { useEffect, useState } from "react";

import { ActionIcon, Button, Modal, Text } from "@mantine/core";
import "./aa.css";
import { IconEye } from "@tabler/icons-react";

const TreeNode = ({ node, }) => {
  const [expanded, setExpanded] = useState(false);
//   const [modal, setModal] = useState(false);
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(0);
//   const [corporate, setCorporate] = useState(false);
//   const [data, setData] = useState(null);
//   const [delId, setDelId] = useState(0);
//   const currentUser = useSelector(selectUserData);

//   const deleteDocument = async () => {
//     const response = await apiWrapWithErrorWithData(
//       removeDocument({ id: delId })
//     );
//     if (response.success) {
//       showNotification({
//         title: "delete document",
//         message: "document deleted",
//         color: "green",
//       });
//       setDeleteModal(false);
//       setDelId(0);
//       window.location.reload();
//     } else {
//       showNotification({
//         title: "delete document",
//         message: "document not deleted",
//         color: "red",
//       });
//     }
//   };
  const toggleExpand = () => setExpanded(!expanded);
  const viewDocuemnt = async (url) => {
    // const response = await apiWrapWithErrorWithData(
    //   openDocument({ hostedLink: url })
    // );
    const response = {success: true, signedUrl: "https://api.com"};
    if (response.success) {
      window.open(`${response.signedUrl}`, "_blank");
    }
  };

  return (
    <div className="tree-node">
      <div onClick={toggleExpand} className="tree-node-label">
        {expanded ? "▼" : "▶"} {node.name || node.title}
      </div>

      {/* Display documents */}
      {expanded && node.documents && node.documents.length > 0 && (
        <div className="documents-list">
          {node.documents.map((doc) =>
           (
            <div key={doc.key} className="document-item">
              <div className="flex justify-between ml-4">
                <div className="text-xl">{doc.title}</div>
                <div className="flex mr-4 gap-4">
                  <ActionIcon
                    color="#46BDE1"
                    onClick={() => viewDocuemnt(doc.url)}
                  >
                    <IconEye color="blue" size={40} />
                  </ActionIcon>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Recursively display subcategories and subsubcategories */}
      {expanded &&
        node.subCategories &&
        Object.keys(node.subCategories).map((sub) => (
          <TreeNode
            key={sub}
            node={{ name: sub, ...node.subCategories[sub] }}
          />
        ))}

      {expanded &&
        node.subsubCategories &&
        Object.keys(node.subsubCategories).map((subsub) => (
          <TreeNode
            key={subsub}
            node={{ name: subsub, ...node.subsubCategories[subsub] }}
          />
        ))}
      
    </div>
  );
};

export default TreeNode;
