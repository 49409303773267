import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  attachFiles,
  getObligationDetails,
  getObligationFiles,
  updateObligationApi,
} from "../apis/userApis";
import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Divider,
  Modal,
  Progress,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import {
  IconFile,
  IconHistory,
  IconHistoryOff,
  IconInfoCircle,
  IconTrash,
} from "@tabler/icons-react";
import file from "./downloadFileLogo.svg";
import { showNotification } from "@mantine/notifications";
import {
  formatDatetoISO,
  getActFrequencyText,
  getDays,
  getDepartmentText,
  getRecFrequencyText,
  getStatusColor,
  getStatusText,
  infoColor,
} from "./utilities";
import BusinessUnitName from "../componenets/getBuName";
import ObligationTranHistoryModal from "./ObligationTranHistoryModal";
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from "../redux/authSlice";
// import {fs} from 'fs';
// import {path} from 'path';
const ViewObligation = () => {
  const { id } = useParams();
  const [configs, setConfigs] = useState({
    loading: true,
    obligationData: null,
  });
  const [ahModal, setAhModal] = useState(false);
  const [ahId, setAhId] = useState(0);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [files, setFiles] = useState([]);
  AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KAY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
    region: `${process.env.REACT_APP_REGION}`,
  });

  const s3 = new AWS.S3();
  useEffect(() => {
    const getObligation = async () => {
      const response = await getObligationDetails({ id: id });
      setConfigs({
        loading: false,
        obligationData: { ...response.obligation, updatedBy: userData.name },
      });
    };
    getObligation();
    const getfiles = async () => {
      const response = await getObligationFiles({ id: id });
      setFiles(response.attachedFiles);
    };
    getfiles();
  }, [id]);

  const uploadFileToAws = async (e) => {
    console.log("yha aaya to h ");
    const file = e.target.files[0];
    console.log("file: ", file);
    const fileName = file.name;
    console.log(fileName);
    const type = file.type;
    console.log(type);
    // const fileStream = fs.createReadStream(file.path);
    const uploadParams = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Key: fileName,
      Body: file,
      ContentType: type,
      Metadata: {
        lastModified: file.lastModified.toString(),
        size: file.size.toString(),
      },
    };

    try {
      const data = await s3.upload(uploadParams).promise();
      console.log("data for file: ", data);
      console.log(`File uploaded successfully. ${data.Location}`);
      const location = data.Location;
      const name = data.key;
      const bucket = data.Bucket;
      const fileforAttach = {
        createdBy: userData.name,
        createdByUserId: userData.id,
        location: location,
        name: name,
        parent: "obligation",
        parentId: parseInt(id),
        type: type,
      };
      const fileAttached = await attachFiles({ body: fileforAttach });
      console.log(fileAttached.response);
      setFiles([...files, fileAttached.response]);
    } catch (err) {
      console.error("Error uploading file:", err);
    }

    // const body =
  };
  const openFile = async ({ file }) => {
    console.log("file is here: ", file);
    const params = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Key: file.fileName,
      Expires: 600 * 60, // URL will expire in 10 hour
    };
    const previewUrl = await s3.getSignedUrl("getObject", params);
    window.open(previewUrl, "_blank");
  };
  const deleteFileFromAws = async ({ e, fileId, url }) => {
    e.preventDefault();
    console.log(url);
    console.log(fileId);
  };

  const navigate = useNavigate();
  const updateObligation = async (e) => {
    e.preventDefault();
    const response = await updateObligationApi({
      body: configs.obligationData,
    });
    console.log(response);
    //dont make complaint when there is no documents uploaded.
    if (response.success) {
      showNotification({
        color: "green",
        title: "obligation update",
        message: "obligation updated",
      });
      if (userData.loginAs === "Ob-DeptAdmin" && userData.loginAs === "Ob-DeptUser") {
        navigate("/assigned");
      } else {
        navigate("/obligationtracker");
      }
    } else {
      showNotification({
        color: "red",
        title: "obligation update",
        message: "obligation not updated",
      });
    }
  };
  const updateObligationnmi = async (id) => {
    // e.preventDefault();
    let updatedData = {
      ...configs.obligationData,
      extraCol3: id,
      compliant: "partially-compliant",
      updatedBy: userData.name,
      remarks: remarks,
    };
    if (id === "2") {
      updatedData = {
        ...configs.obligationData,
        extraCol3: id,
        updatedBy: userData.name,
        remarks: remarks,
      };
    }
    if (id === "3") {
      updatedData = {
        ...configs.obligationData,
        extraCol3: id,
        compliant: "compliant",
        updatedBy: userData.name,
        remarks: remarks,
      };
    }

    const response = await updateObligationApi({
      body: updatedData,
    });
    console.log(response);
    if (response.success) {
      showNotification({
        color: "green",
        title: "obligation update",
        message: "obligation updated",
      });
      if (userData.loginAs === "Ob-DeptAdmin" || userData.loginAs === "Ob-DeptUser") {
        navigate("/assigned");
      } else {
        navigate("/obligationtracker");
      }
    } else {
      showNotification({
        color: "red",
        title: "obligation update",
        message: "obligation not updated",
      });
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [remarks, setRemarks] = useState("");
  console.log("files: ", files);
  console.log("obligation data: ", configs.obligationData);
  return (
    <>
      {!configs.loading && configs.obligationData && (
        <>
          {/* <Text className="m-4 font-bold text-md">obligation {id} details</Text> */}
          <Modal
            opened={ahModal}
            onClose={() => {
              setAhModal(false);
              setAhId(0);
            }}
            title="oblligation action history"
            size="calc(80vw)"
          >
            <ObligationTranHistoryModal id={ahId} />
          </Modal>
          {!configs.loading && (
            <>
              <div className="grid grid-cols-2 gap-4 m-4">
                <div className="flex justify-start">
                  <Text className=" font-semibold ">obligation-{id}</Text>
                </div>
                <div className="flex justify-end">
                  <Badge
                    variant="light"
                    color={getStatusColor(configs.obligationData.status)}
                  >
                    {getStatusText(configs.obligationData.status)}
                  </Badge>
                  <div className="flex flex-row ml-4 my-auto item-center">
                    <ActionIcon
                      onClick={() => {
                        setAhId(id);
                        setAhModal(true);
                      }}
                      color="white"
                    >
                      <IconHistory size={20} />
                    </ActionIcon>

                    {/* )} */}
                  </div>
                </div>
                <div className="flex justify-start">
                  <Text className=" font-semibold text-sm">
                    <span className="font-normal">created on :</span>
                    {formatDatetoISO(configs.obligationData.startDate)}
                  </Text>
                </div>

                <div className="flex justify-end">
                  <Text className=" font-semibold text-sm">
                    <span className="font-normal">
                      pending since {getDays(configs.obligationData.startDate)}{" "}
                      days
                    </span>
                  </Text>
                </div>
              </div>
              <Text className="font-semibold mt-6 mx-4 mb-4">
                Agreement Details:
              </Text>
              <div className="grid grid-cols-4 gap-4 m-4 text-center">
                <div>
                  <Text className="font-light">department</Text>
                  <Text className="">
                    {getDepartmentText(configs.obligationData.department)}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">AIN</Text>
                  <Text className="">
                    {configs.obligationData.local && "local-"}
                    {configs.obligationData.connectedAgreement}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">agreement type</Text>
                  <Text className="">{configs.obligationData.extraCol1}</Text>
                </div>
                <div>
                  <Text className="font-light">business unit</Text>
                  <Text className="">
                    <BusinessUnitName
                      businessUnitId={configs.obligationData.businessUnit}
                    />
                  </Text>
                </div>
              </div>
              <Text className="m-4 font-semibold">Obligation Details: </Text>
              <div className="grid grid-cols-4 gap-4 m-4 text-center">
                <div>
                  <Text className="font-light">cl no.</Text>
                  <Text className="">{configs.obligationData.clNo}</Text>
                </div>
                <div>
                  <Text className="font-light">title</Text>
                  <Text className="">{configs.obligationData.title}</Text>
                </div>
                <div>
                  <Text className="font-light">oblligation</Text>
                  <Text className="">{configs.obligationData.desc}</Text>
                </div>
                {configs.obligationData.actFrequency !== 1 && (
                  <div>
                    <Text className="font-light">rec. frequency</Text>
                    <Text className="">
                      {getRecFrequencyText(configs.obligationData.recFrequency)}
                    </Text>
                  </div>
                )}

                <div>
                  <Text className="font-light">act. frequency</Text>
                  <Text className="">
                    {getActFrequencyText(configs.obligationData.actFrequency)}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">reminder time</Text>
                  <Text className="">
                    {configs.obligationData.reminderTime}-days
                  </Text>
                </div>
                <div>
                  <Text className="font-light">due date</Text>{" "}
                  <Text>{formatDatetoISO(configs.obligationData.duedate)}</Text>
                </div>
              </div>
              <Divider className="m-4" />
              <div className="grid  grid-cols-2 gap-4 m-4">
                <div className="w-1/2">
                  {userData.loginAs === "Ob-DeptAdmin" ||
                  userData.loginAs === "Ob-DeptUser" ? (
                    <Select
                      label="compliance"
                      value={configs.obligationData.compliant}
                      onChange={(val) => {
                        setConfigs({
                          ...configs,
                          obligationData: {
                            ...configs.obligationData,
                            compliant: val,
                          },
                        });
                      }}
                      data={
                        new Date() > new Date(configs.obligationData?.duedate)
                          ? [
                              {
                                label: "non-compliant",
                                value: "non-compliant",
                              },
                              {
                                label: "partially-compliant",
                                value: "partially-compliant",
                              },
                            ]
                          : [
                              {
                                label: "non-compliant",
                                value: "non-compliant",
                              },
                              {
                                label: "partially-compliant",
                                value: "partially-compliant",
                              },
                              { label: "compliant", value: "compliant" },
                            ]
                      }
                    />
                  ) : (
                    <div>
                      <Text className="font-light">compliance : </Text>
                      <Text>{configs.obligationData.compliant}</Text>
                    </div>
                  )}
                </div>
                <div>
                  <Textarea
                    label="remarks"
                    rows={2}
                    type="text"
                    value={configs.obligationData.remarks}
                    onChange={(e) =>
                      setConfigs({
                        ...configs,
                        obligationData: {
                          ...configs.obligationData,
                          remarks: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}

          {(userData.loginAs === "Ob-DeptAdmin" ||
            userData.loginAs === "Ob-DeptUser") && (
            <div className="flex items-center justify-center w-full m-4">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    ariaHidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span>Click to upload</span> or drag and drop
                  </p>
                  
                </div>
                <input
                  onChange={async (e) => {
                    await uploadFileToAws(e);
                  }}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
          )}
          <div className="mt-4">
            {files?.length > 0 &&
              files.map((file) => {
                return (
                  <div key={file.id} className="flex block h-10 mb-4 w-fulll">
                    <div className="center flex items-center justify-center w-1/12">
                      <svg
                        width="24"
                        height="35"
                        viewBox="0 0 24 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 9.29688V0H1.5C0.66875 0 0 0.731445 0 1.64062V33.3594C0 34.2686 0.66875 35 1.5 35H22.5C23.3312 35 24 34.2686 24 33.3594V10.9375H15.5C14.675 10.9375 14 10.1992 14 9.29688ZM18.0737 24.0632H14V29.5319C14 30.1362 13.5525 30.6257 13 30.6257H11C10.4475 30.6257 10 30.1362 10 29.5319V24.0632H5.92625C5.03375 24.0632 4.58813 22.8813 5.22188 22.1929L11.2481 15.6509C11.6637 15.199 12.335 15.199 12.7506 15.6509L18.7769 22.1929C19.4112 22.8813 18.9662 24.0632 18.0737 24.0632ZM23.5625 7.17773L17.4438 0.478516C17.1625 0.170898 16.7812 0 16.3813 0H16V8.75H24V8.33301C24 7.90234 23.8438 7.48535 23.5625 7.17773Z"
                          fill="#191D23"
                        />
                      </svg>
                    </div>
                    <div className="flex flex-col pl-3 w-full">
                      <div className="flex flex-row justify-between">
                        <div
                          style={{
                            lineHeight: "1.25rem",
                            letterSpacing: "-0.0313rem",
                            color: "#191D23",
                          }}
                        >
                          <Text className="font-normal">{file.fileName}</Text>
                        </div>
                      </div>
                      <div className="mt-3">
                        <Progress
                          color={file.errored ? "red" : "teal"}
                          size="sm"
                          // animate={
                          //   file.fetchingUploadUrl ||
                          //   file.attaching === loadingStates.LOADING
                          // }
                          // value={
                          //   file.fetchingUploadUrl || file.errored
                          //     ? 100
                          //     : file.uploadPercentage
                          // }
                          value={100}
                        />
                      </div>
                    </div>
                    <div className="center inline-block">
                      <Button
                        // onClick={downloadFile(index)}
                        className="mx-3 w-60"
                        color="#46BDE1"
                        // disabled={file.downloading === loadingStates.LOADING}
                        // classNames={`${styles.btn} mx-3`}
                        variant="outline"
                        style={{
                          color: "#46BDE1",
                          borderColor: "#46BDE1",
                        }}
                        onClick={() => openFile({ file: file })}
                      >
                        Open
                      </Button>
                    </div>
                    {(userData.loginAs === "Ob-DeptAdmin" ||
                      userData.loginAs === "Ob-DeptUser") && (
                      <div className="center w-1/12 px-auto py-auto inline-block">
                        <IconTrash
                          onClick={(e) =>
                            deleteFileFromAws({
                              e: e,
                              fileId: file.id,
                              url: file.hostedLink,
                            })
                          }
                          className="mx-auto"
                          size={30}
                          color="red"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          {(userData.loginAs === "Ob-DeptAdmin" ||
            userData.loginAs === "Ob-DeptUser") &&
            configs.obligationData.extraCol3 !== "1" && (
              <div className="flex mt-6 md justify-center">
                <Button
                  onClick={(e) => {
                    setConfigs({
                      ...configs,
                      obligationData: {
                        ...configs.obligationData,
                        updatedBy: userData.name,
                      },
                    });
                    updateObligation(e);
                  }}
                  color="#1a73e8"
                  style={{
                    width: "150px",
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          {(userData.loginAs === "Ob-DeptAdmin" ||
            userData.loginAs === "Ob-DeptUser") &&
            configs.obligationData.extraCol3 === "1" && (
              <div className="flex mt-6 lg justify-center">
                <Button
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                  color="#1a73e8"
                >
                  submit info
                </Button>
              </div>
            )}
          {userData.loginAs === "Ob-Admin" &&
            configs.obligationData.extraCol3 === "" && (
              <div className="flex mt-6 md justify-center">
                <Button
                  className="mt-4"
                  onClick={async (e) => {
                    setShowModal(true);
                  }}
                >
                  need more info
                </Button>
              </div>
            )}
          {userData.loginAs === "Ob-Admin" &&
            configs.obligationData.extraCol3 === "2" && (
              <div className="flex mt-6 md justify-center">
                <Button
                  className="mt-4"
                  onClick={async (e) => {
                    setShowModal(true);
                  }}
                  color="green"
                >
                  accept info
                </Button>
              </div>
            )}
          {userData.loginAs === "Ob-Admin" &&
            configs.obligationData.extraCol3 === "3" && (
              <div className="flex mt-6 md justify-center">
                <Button
                  className="mt-4"
                  // onClick={async (e) => {
                  //  setShowModal(true);
                  // }}
                  color="green"
                >
                  information submitted
                </Button>
              </div>
            )}
          <Modal title="nmi update" onClose={() => setShowModal(false)} opened={showModal}>
            
            <Textarea
              className="border border-black rounded w-full"
              minRows={5}
              placeholder="remark goes here..."
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
            {(userData.loginAs === "Ob-DeptAdmin" ||
              userData.loginAs === "Ob-DeptUser") &&
              configs.obligationData.extraCol3 === "1" && (
                <div className="flex mt-6 lg justify-between">
                  <Button className="mt-4" onClick={() => setShowModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    color="green"
                    onClick={(e) => {
                      updateObligationnmi("2");
                    }}
                    className="mt-4"
                  >
                    submit
                  </Button>
                </div>
              )}
            {userData.loginAs === "Ob-Admin" && (
              <div className="flex justify-between">
                {configs.obligationData.extraCol3 === "2" ? (
                  <Button
                    onClick={async (e) => {
                      console.log(configs.obligationData);
                      updateObligationnmi("1");
                    }}
                    className=" mt-4 flex justify-start"
                    color="blue"
                  >
                    need more info
                  </Button>
                ) : (
                  <Button className="mt-4" onClick={() => setShowModal(false)}>
                    Cancel
                  </Button>
                )}
                <Button
                  className="mt-4"
                  color={
                    configs.obligationData.extraCol3 === "" ? "blue" : "green"
                  }
                  onClick={async (e) => {
                    if (configs.obligationData.extraCol3 === "2") {
                      updateObligationnmi("3");
                    } else {
                      console.log(configs.obligationData);
                      updateObligationnmi("1");
                    }
                  }}
                >
                  {configs.obligationData.extraCol3 === ""
                    ? "need more info"
                    : "accept"}
                </Button>
              </div>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default ViewObligation;
