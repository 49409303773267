import { Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "./utilities";
import { getNotifications } from "../apis/userApis";

const Notifications = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const filter = {
    role: userData.loginAs,
    department: userData.department,
  };

  const [configs, setConfigs] = useState({
    loading: true,
    notifications: null,
  });
  useEffect(() => {
    const getallNotifications = async () => {
      const resp = await getNotifications({ filter });
      setConfigs({
        loading: false,
        notifications: resp.notifications,
      });
    };
    getallNotifications();
  }, []);

  return (
    <div>
      <Text className="flex justify-center font-bold text-xl my-4">
        Notifications
      </Text>
      {!configs.loading &&
        configs.notifications &&
        configs.notifications.length && (
          <>
            {configs.notifications.map((notification) => (
              <div className="w-full mx-5  bg-blue-400 rounded-2xl">
                <div className="ml-5 px-3 py-5 bg-white rounded-2xl flex justify-between">
                  <Text className="ml-2 text-base font-500">
                    {notification.notificationText}
                  </Text>
                  <div className="flex justify-between">
                    <div
                      className="flex flex-row mr-2 items-center"
                      style={{ width: "100px" }}
                    >
                      <img src="./assets/calender.svg" alt="calendar" />
                      <div className={` ml-1`}>
                        <Text>{formatDate(notification.createdAt)}</Text>
                      </div>
                    </div>
                    <div
                      className="flex flex-row mr-2 items-center"
                      style={{ width: "80px" }}
                    >
                      <img src="./assets/clock.svg" alt="clock" />
                      <div className={` ml-1`}>
                        <Text>{formatTime(notification.createdAt)}</Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
    </div>
  );
};

export default Notifications;
