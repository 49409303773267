import { Button, Modal, MultiSelect, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getDocuments } from "../apis/userApis";
import DocumentsTree from "../componenets/documentTree";

const MyDocuments = () => {
  const [modal, setModal] = useState(false);
  const [tagWords, setTagWords] = useState(null);
  const [configc, setConfigc] = useState({
    loading: true,
    categories: null,
  });
//   const handleReload = () => {
//     setReload((prevReload) => !prevReload);
//   };

  // Rerun only when corporate changes

  useEffect(() => {
    const getdocuments = async () => {
      let filter = {};

      //   if (searchTagWords.length > 0) {
      //     filter = {
      //       OR: searchTagWords.map((tag) => ({
      //         tageWords: { contains: tag },
      //       })),
      //     };
      //   }
      const respo = await getDocuments({ filter: filter });
      console.log("response for categories: ", respo);
      setConfigc({
        loading: false,
        categories: respo?.response?.data,
      });
    };
    getdocuments();
  }, []);
  //   console.log("searched tage words: ",searchTagWords);
  return (
    <>
      <div className="m-2">
        <div>
          <h1
            style={{
              fontSize: "22px",
              fontWeight: "600",
              letterSpacing: "1px",
              fontFamily: "lexend",
              whiteSpace: "nowrap",
              paddingBottom: "13px",
              position: "relative",
              color: "black",
            }}
          >
            Documents
          </h1>
        </div>
        <div className="flex mt-4 justify-between">
          {/* <div className="flex">
            {tagWords && (
              <MultiSelect
              onChange={(val) => setSearchTagWords(val)}
                className="mr-4"
                data={tagWords}
              />
            )}
            <Button onClick={()=> setReload(!reload)} color="green">search</Button>
          </div> */}
          {/* {currentUser.loginAs.id === 4 ?
          <Button onClick={() => setModal(true)}>add document</Button>: <div></div>
          } */}
        </div>
        {!configc.loading && (
          <div>
            <DocumentsTree
              documents={configc.categories}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MyDocuments;
